// @ts-nocheck

import React, { useState, useEffect, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Input from "../../components/Form/Input";
import IconButton from "../../components/Buttons/IconButton";
import DropZone from "../../components/Form/DropZone";
import { toast } from "react-toastify";
import { hintAmountFunded, hintCarNames, validateAmountFunded, validateCarName } from "../../utils/validates";
import { addCar, updateCar } from "../../services/axios";

import CarImg from "../../assets/image/car.svg"
import DocumentImg from "../../assets/image/document.svg"
import BrushImg from "../../assets/image/brush.svg";
import GuaranteImg from "../../assets/image/guarantee.svg"
import EuroImg from "../../assets/image/euro.svg"
import CityImg from "../../assets/image/city.svg"
import CheckImg from "../../assets/image/check.svg"
import TrashImg from "../../assets/icons/trash.svg"
import DateSinglePicker from "../../components/Form/DatePicker";
import Textarea from "../../components/Form/Textarea";
import { getFileUrl } from "../../utils/utils";
import { apiMultipart } from "../../services/api";
import LoadingSpinner from "../../components/loading";
import UploadIcon from "../../assets/icons/upload.svg";


const CarEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [carInfo, setCarInfo] = useState(null);

    const [nameValid, setNameValid] = useState(false);
    const [amountFundedValid, setAmountFundedValid] = useState(false);

    useEffect(() => {
        if (location.state) {
            setCarInfo(location.state);
            setIsEdit(true);
        }
    }, [location]);


    useEffect(() => {
        // console.log("🚀 ~ CarEdit ~ carInfo:", carInfo)
    }, [carInfo]);

    const handleChange = (name, value) => {
        setCarInfo(prev => ({
            ...carInfo,
            [name]: value
        }));
    }

    const handleChangeNftMetadata = (name, value) => {
        let nftMetadata = {};
        if (carInfo?.nftMetadata) {
            nftMetadata = { ...(carInfo?.nftMetadata) };
        }
        nftMetadata[name] = value;
        setCarInfo(prev => ({
            ...carInfo,
            nftMetadata
        }));
    }

    const handleChangeKeypoints = (index, name, value) => {
        let keypoints = [{}, {}, {}, {}];       // 4 item
        if (carInfo?.keypoints && carInfo?.keypoints?.length === 4) {
            keypoints = [...(carInfo?.keypoints)];
        }
        keypoints[index][name] = value;
        setCarInfo(prev => ({
            ...carInfo,
            keypoints
        }));
    }

    const handleChangePresentation = (name, value) => {
        let presentation = {};
        if (carInfo?.presentation) {
            presentation = { ...(carInfo?.presentation) };
        }
        presentation[name] = value;
        setCarInfo(prev => ({
            ...carInfo,
            presentation
        }));
    }

    const fileRef = useRef(null);
    const handleSelectOwnerImage = async (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData();
            formData.append(`file0`, e.target.files[0]);
            formData.append('kind', "cars");

            const res = await apiMultipart.post('/upload', formData);
            if (res?.status === 200) {
                let ownerPresentation = {};
                if (carInfo?.ownerPresentation) {
                    ownerPresentation = { ...(carInfo?.ownerPresentation) };
                }
                ownerPresentation["image"] = res.data.result[0];
                setCarInfo(prev => ({
                    ...carInfo,
                    ownerPresentation
                }));
            } else {
                toast.error("File upload failed!");
            }
        }
    }

    const handleChangeOwnerPresentation = (name, value) => {
        let ownerPresentation = {};
        if (carInfo?.ownerPresentation) {
            ownerPresentation = { ...(carInfo?.ownerPresentation) };
        }
        ownerPresentation[name] = value;
        setCarInfo(prev => ({
            ...carInfo,
            ownerPresentation
        }));
    }

    const handleChangeOwnerPresentationInfo = (index, name, value) => {
        let infos = carInfo?.ownerPresentation.infos.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item,
                    [name]: value
                }
            } else {
                return item;
            }
        });
        setCarInfo(prev => ({
            ...carInfo,
            ownerPresentation: {
                ...(carInfo?.ownerPresentation),
                infos
            }
        }));
    }
    const handleAddOwnerPresentationInfo = () => {
        if (!carInfo) {
            setCarInfo({
                ownerPresentation: {
                    infos: [{}]
                }
            })
        } else if (!carInfo.ownerPresentation) {
            setCarInfo({
                ...carInfo,
                ownerPresentation: {
                    infos: [{}]
                }
            })
        } else {
            let infos = [{}];
            if (carInfo?.ownerPresentation?.infos?.length > 0) {
                infos = [...(carInfo?.ownerPresentation?.infos), {}];
            }

            setCarInfo(prev => ({
                ...carInfo,
                ownerPresentation: {
                    ...(carInfo?.ownerPresentation),
                    infos
                }
            }));
        }
    }
    const handleRemoveOwnerPresentationInfo = (index) => {
        setCarInfo(prev => ({
            ...carInfo,
            ownerPresentation: {
                ...(carInfo?.ownerPresentation),
                infos: [
                    ...(carInfo?.ownerPresentation?.infos.slice(0, index)),
                    ...(carInfo?.ownerPresentation?.infos.slice(index + 1))
                ]
            }
        }));
    }

    const handleChangeTimeline = (index, name, value) => {
        let timeline = carInfo?.timeline?.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item,
                    [name]: value
                }
            } else {
                return item;
            }
        });
        setCarInfo(prev => ({
            ...carInfo,
            timeline
        }));
    }

    const handleAddTimeline = () => {
        if (!carInfo) {
            setCarInfo({
                timeline: [{}]
            })
        } else {
            let timeline = [{}];
            if (carInfo?.timeline?.length > 0) {
                timeline = [...(carInfo?.timeline), {}];
            }
            setCarInfo(prev => ({
                ...carInfo,
                timeline
            }));
        }
    }
    const handleRemoveTimeline = (index) => {
        setCarInfo(prev => ({
            ...carInfo,
            timeline: [
                ...(carInfo?.timeline.slice(0, index)),
                ...(carInfo?.timeline.slice(index + 1))
            ]
        }));
    }

    const handleAcceptPhotos = (files) => {
        setCarInfo(prev => ({
            ...carInfo,
            photos: files,
        }));
    }
    const handleAcceptFile = (name, files) => {
        setCarInfo(prev => ({
            ...carInfo,
            [name]: files[0],
        }));
    }
    const handleDeleteFiles = (name) => {
        setCarInfo(prev => ({
            ...carInfo,
            [name]: null,
        }));
    }

    const fileBronzeRef = useRef(null);
    const fileSilverRef = useRef(null);
    const fileGoldRef = useRef(null);
    const fileGreenRef = useRef(null);
    const filePurpleRef = useRef(null);
    const fileBlackRef = useRef(null);
    const handleNFTImage = async (e, color) => {
        if (e.target.files.length > 0) {
            const formData = new FormData();
            formData.append(`file0`, e.target.files[0]);
            formData.append('kind', "car");

            const res = await apiMultipart.post('/upload', formData);
            if (res?.status === 200) {
                let nftImages = {};
                if (carInfo?.nftImages) {
                    nftImages = { ...(carInfo.nftImages) };
                }
                nftImages[color] = res.data.result[0];
                setCarInfo(prev => ({
                    ...carInfo,
                    nftImages
                }));
            } else {
                toast.error("File upload failed!");
            }
        }
    }

    const handleSubmit = async () => {
        if (!nameValid || !amountFundedValid) {
            return toast.error("Values are incorrect!");
        }
        if (carInfo.photos === undefined || carInfo.photos?.length === 0) {
            return toast.error("Upload car photos!");
        }

        setIsLoading(true);
        const res = isEdit ? await updateCar(carInfo._id, carInfo) : await addCar(carInfo);
        if (res.status === 200) {
            toast.success("Successfully saved!");
            navigate("/cars");
        } else {
            toast.error(res.data.message);
        }
        setIsLoading(false);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex flex-col items-start">
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            {isEdit ? "Edit Car" : "Add New Car"}
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                            {isEdit ? "Edit car details and documents" : "Add new cars with details and documents"}
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col max-w-[800px] justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <Input
                            label="Car Name/Model"
                            value={carInfo?.name}
                            setValue={(value) => handleChange("name", value)}
                            required={true}
                            checkValid={validateCarName}
                            hint={hintCarNames}
                            isValid={setNameValid}
                        />
                        <Input
                            label="Amount to funded"
                            value={carInfo?.amountFunded}
                            setValue={(value) => handleChange("amountFunded", value)}
                            type="number"
                            required={true}
                            checkValid={validateAmountFunded}
                            hint={hintAmountFunded}
                            isValid={setAmountFundedValid}
                        />
                        <Input
                            label="Horizon for refund"
                            value={carInfo?.horizonRefund}
                            setValue={(value) => handleChange("horizonRefund", value)}
                        />
                        <Input
                            label="Vehicle resale year"
                            value={carInfo?.resaleYear}
                            setValue={(value) => handleChange("resaleYear", value)}
                        />
                        <Input
                            label="Fixed revenue /year"
                            value={carInfo?.fixedRevenue}
                            type="number"
                            setValue={(value) => handleChange("fixedRevenue", value)}
                        />
                        <Input
                            label="Vehicle place"
                            value={carInfo?.place}
                            setValue={(value) => handleChange("place", value)}
                        />
                        <div className="w-full">
                            <p class="text-black text-[16px] font-[500] mb-[20px]">NFT Ownership</p>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <div className="relative">
                                    <Input
                                        label={<span>0.10% of NFT (<span className="text-[#714D32]">Bronze</span>)</span>}
                                        value={carInfo?.amountFunded ? `$${carInfo?.amountFunded / 1000} USDC` : ''}
                                        setValue={(value) => { }}
                                        readOnly
                                    />
                                    <input type="file" className="hidden" ref={fileBronzeRef} onChange={e => handleNFTImage(e, "Bronze")} />
                                    <div className="absolute w-[80px] h-[49px] bg-white cursor-pointer bottom-0 right-0 rounded-lg border border-[#b6b6b6] flex items-center justify-center"
                                        onClick={() => fileBronzeRef?.current && fileBronzeRef?.current.click()}>
                                        <img src={UploadIcon} alt="upload" className="w-6" />
                                        {carInfo?.nftImages?.Bronze &&
                                            <img src={CheckImg} alt="upload" className="w-4 absolute right-1" />
                                        }
                                    </div>
                                </div>
                                <div className="relative">
                                    <Input
                                        label={<span>0.25% of NFT (<span className="text-[#A3A3A3]">Silver</span>)</span>}
                                        value={carInfo?.amountFunded ? `$${carInfo?.amountFunded / 1000 * 2.5} USDC` : ''}
                                        setValue={(value) => { }}
                                        readOnly
                                    />
                                    <input type="file" className="hidden" ref={fileSilverRef} onChange={e => handleNFTImage(e, "Silver")} />
                                    <div className="absolute w-[80px] h-[49px] bg-white cursor-pointer bottom-0 right-0 rounded-lg border border-[#b6b6b6] flex items-center justify-center"
                                        onClick={() => fileSilverRef?.current && fileSilverRef?.current.click()}>
                                        <img src={UploadIcon} alt="upload" className="w-6" />
                                        {carInfo?.nftImages?.Silver &&
                                            <img src={CheckImg} alt="upload" className="w-4 absolute right-1" />
                                        }
                                    </div>
                                </div>
                                <div className="relative">
                                    <Input
                                        label={<span>0.50% of NFT (<span className="text-[#FEC802]">Gold</span>)</span>}
                                        value={carInfo?.amountFunded ? `$${carInfo?.amountFunded / 1000 * 5} USDC` : ''}
                                        setValue={(value) => { }}
                                        readOnly
                                    />
                                    <input type="file" className="hidden" ref={fileGoldRef} onChange={e => handleNFTImage(e, "Gold")} />
                                    <div className="absolute w-[80px] h-[49px] bg-white cursor-pointer bottom-0 right-0 rounded-lg border border-[#b6b6b6] flex items-center justify-center"
                                        onClick={() => fileGoldRef?.current && fileGoldRef?.current.click()}>
                                        <img src={UploadIcon} alt="upload" className="w-6" />
                                        {carInfo?.nftImages?.Gold &&
                                            <img src={CheckImg} alt="upload" className="w-4 absolute right-1" />
                                        }
                                    </div>
                                </div>
                                <div className="relative">
                                    <Input
                                        label={<span>1% of NFT (<span className="text-[#24E024]">Green</span>)</span>}
                                        value={carInfo?.amountFunded ? `$${carInfo?.amountFunded / 100} USDC` : ''}
                                        setValue={(value) => { }}
                                        readOnly
                                    />
                                    <input type="file" className="hidden" ref={fileGreenRef} onChange={e => handleNFTImage(e, "Green")} />
                                    <div className="absolute w-[80px] h-[49px] bg-white cursor-pointer bottom-0 right-0 rounded-lg border border-[#b6b6b6] flex items-center justify-center"
                                        onClick={() => fileGreenRef?.current && fileGreenRef?.current.click()}>
                                        <img src={UploadIcon} alt="upload" className="w-6" />
                                        {carInfo?.nftImages?.Green &&
                                            <img src={CheckImg} alt="upload" className="w-4 absolute right-1" />
                                        }
                                    </div>
                                </div>
                                <div className="relative">
                                    <Input
                                        label={<span>2% of NFT (<span className="text-[#AF32FD]">Purple</span>)</span>}
                                        value={carInfo?.amountFunded ? `$${carInfo?.amountFunded / 50} USDC` : ''}
                                        setValue={(value) => { }}
                                        readOnly
                                    />
                                    <input type="file" className="hidden" ref={filePurpleRef} onChange={e => handleNFTImage(e, "Purple")} />
                                    <div className="absolute w-[80px] h-[49px] bg-white cursor-pointer bottom-0 right-0 rounded-lg border border-[#b6b6b6] flex items-center justify-center"
                                        onClick={() => filePurpleRef?.current && filePurpleRef?.current.click()}>
                                        <img src={UploadIcon} alt="upload" className="w-6" />
                                        {carInfo?.nftImages?.Purple &&
                                            <img src={CheckImg} alt="upload" className="w-4 absolute right-1" />
                                        }
                                    </div>
                                </div>
                                <div className="relative">
                                    <Input
                                        label={<span>5% of NFT (<span className="text-[#000000]">Black</span>)</span>}
                                        value={carInfo?.amountFunded ? `$${carInfo?.amountFunded / 20} USDC` : ''}
                                        setValue={(value) => { }}
                                        readOnly
                                    />
                                    <input type="file" className="hidden" ref={fileBlackRef} onChange={e => handleNFTImage(e, "Black")} />
                                    <div className="absolute w-[80px] h-[49px] bg-white cursor-pointer bottom-0 right-0 rounded-lg border border-[#b6b6b6] flex items-center justify-center"
                                        onClick={() => fileBlackRef?.current && fileBlackRef?.current.click()}>
                                        <img src={UploadIcon} alt="upload" className="w-6" />
                                        {carInfo?.nftImages?.Black &&
                                            <img src={CheckImg} alt="upload" className="w-4 absolute right-1" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex flex-col sm:flex-row gap-6">
                            <DateSinglePicker
                                label="Start Date"
                                date={carInfo?.startAt}
                                setDate={(value) => handleChange("startAt", value)}
                            />
                            <DateSinglePicker
                                label="End Date"
                                date={carInfo?.endAt}
                                setDate={(value) => handleChange("endAt", value)}
                            />
                        </div>
                    </div>

                    <div className="w-full bg-white rounded-[12px] p-[20px]">
                        <p className="text-black3 text-[20px] font-[600] font-Urbanist mb-[20px]">
                            NFT Metadata
                        </p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <Input
                                label="Car Name"
                                value={carInfo?.nftMetadata?.carName}
                                setValue={(value) => handleChangeNftMetadata("carName", value)}
                            />
                            <Input
                                label="Car Model"
                                value={carInfo?.nftMetadata?.carModel}
                                setValue={(value) => handleChangeNftMetadata("carModel", value)}
                            />
                            <Input
                                label="Color of Car"
                                value={carInfo?.nftMetadata?.carColor}
                                setValue={(value) => handleChangeNftMetadata("carColor", value)}
                            />
                            <Input
                                label="Fiscal power"
                                value={carInfo?.nftMetadata?.fiscalPower}
                                setValue={(value) => handleChangeNftMetadata("fiscalPower", value)}
                            />
                            <Input
                                label="Kilometers"
                                type="number"
                                value={carInfo?.nftMetadata?.kilometers}
                                setValue={(value) => handleChangeNftMetadata("kilometers", value)}
                            />
                            <Input
                                label="Year of car"
                                type="number"
                                value={carInfo?.nftMetadata?.carYear}
                                setValue={(value) => handleChangeNftMetadata("carYear", value)}
                            />
                        </div>
                    </div>

                    <div className="w-full  bg-white rounded-[12px] p-[20px]">
                        <p className="text-black3 text-[20px] font-[600] font-Urbanist">
                            Key points
                        </p>
                        <div className="flex flex-col items-center">
                            <div className="w-full flex flex-col md:flex-row">
                                <div className={`w-full md:w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] bg-white`}>
                                    <img className="w-[20px] h-[20px] mt-[4px]" src={CarImg} alt="" />
                                    <div className="flex flex-col md:ml-[12px] gap-2 w-full">
                                        <Input
                                            size="small"
                                            placeholder="Current offer"
                                            value={carInfo?.keypoints?.[0]?.name}
                                            setValue={(value) => handleChangeKeypoints(0, "name", value)}
                                        />
                                        <Input
                                            placeholder="Current offer description"
                                            value={carInfo?.keypoints?.[0]?.value}
                                            setValue={(value) => handleChangeKeypoints(0, "value", value)}
                                        />
                                    </div>
                                </div>
                                <div className={`w-full md:w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] bg-white`}>
                                    <img className="w-[20px] h-[20px] mt-[4px]" src={DocumentImg} alt="" />
                                    <div className="flex flex-col md:ml-[12px] gap-2 w-full">
                                        <Input
                                            size="small"
                                            placeholder="Securing interests"
                                            value={carInfo?.keypoints?.[1]?.name}
                                            setValue={(value) => handleChangeKeypoints(1, "name", value)}
                                        />
                                        <Input
                                            placeholder="Securing interests description"
                                            value={carInfo?.keypoints?.[1]?.value}
                                            setValue={(value) => handleChangeKeypoints(1, "value", value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col md:flex-row items-center bg-white gap-2">
                                <div className={`w-full md:w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] bg-white`}>
                                    <img className="w-[24px] h-[24px] mt-[4px]" src={BrushImg} alt="" />
                                    <div className="flex flex-col md:ml-[12px] gap-2 w-full">
                                        <Input
                                            size="small"
                                            placeholder="Transformation"
                                            value={carInfo?.keypoints?.[2]?.name}
                                            setValue={(value) => handleChangeKeypoints(2, "name", value)}
                                        />
                                        <Input
                                            placeholder="Transformation description"
                                            value={carInfo?.keypoints?.[2]?.value}
                                            setValue={(value) => handleChangeKeypoints(2, "value", value)}
                                        />
                                    </div>
                                </div>
                                <div className={`w-full md:w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] bg-white`}>
                                    <img className="w-[20px] h-[20px] mt-[4px]" src={GuaranteImg} alt="" />
                                    <div className="flex flex-col md:ml-[12px] gap-2 w-full">
                                        <Input
                                            size="small"
                                            placeholder="Guarantees"
                                            value={carInfo?.keypoints?.[3]?.name}
                                            setValue={(value) => handleChangeKeypoints(3, "name", value)}
                                        />
                                        <Input
                                            placeholder="Guarantees description"
                                            value={carInfo?.keypoints?.[3]?.value}
                                            setValue={(value) => handleChangeKeypoints(3, "value", value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full  bg-white rounded-[12px] p-[20px]">
                        <p className="text-black3 text-[20px] font-[600] font-Urbanist">
                            Presentation
                        </p>
                        <div className="w-full flex flex-wrap items-center gap-2 justify-between mt-[24px]">
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex items-center justify-center bg-background4 w-[36px] h-[36px] rounded-full">
                                    <img src={CarImg} alt="gate" />
                                </div>
                                <Input
                                    size="small"
                                    placeholder="Lorem ip car"
                                    className="w-[150px]"
                                    value={carInfo?.presentation?.info}
                                    setValue={(value) => handleChangePresentation("info", value)}
                                />
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex items-center justify-center bg-background4 w-[36px] h-[36px] rounded-full">
                                    <img src={EuroImg} alt="gate" />
                                </div>
                                <Input
                                    size="small"
                                    placeholder="Car value"
                                    className="w-[150px]"
                                    value={carInfo?.presentation?.carValue}
                                    setValue={(value) => handleChangePresentation("carValue", value)}
                                />
                            </div>
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex items-center justify-center bg-background4 w-[36px] h-[36px] rounded-full">
                                    <img src={CityImg} alt="city" />
                                </div>
                                <Input
                                    size="small"
                                    placeholder="City"
                                    className="w-[150px]"
                                    value={carInfo?.presentation?.city}
                                    setValue={(value) => handleChangePresentation("city", value)}
                                />
                            </div>
                        </div>
                        <p className="font-Urbanist text-[16px] font-[600] text-black mt-[24px]">
                            Presentation of the Project Owner
                        </p>
                        <div className=" font-Urbanist text-[14px] md:text-[16px] font-[500] text-unseletedText mt-[12px]">
                            <Textarea
                                placeholder="Presentation of the Project Owner"
                                rows={5}
                                value={carInfo?.presentation?.owner}
                                setValue={(value) => handleChangePresentation("owner", value)}
                            />
                        </div>
                    </div>

                    <div className="w-full  bg-white rounded-[12px] p-[20px]">
                        <div className="flex  flex-row items-center gap-4">
                            <div className="w-[36px] h-[36px] md:w-[64px] md:h-[64px] rounded-full border border-[#0000001A]" onClick={() => fileRef?.current.click()}>
                                {carInfo?.ownerPresentation?.image &&
                                    <img src={getFileUrl(carInfo?.ownerPresentation?.image.path)} alt="person" className="w-[36px] h-[36px] md:w-[64px] md:h-[64px] rounded-full" />
                                }
                                <input type="file" className="hidden" ref={fileRef} onChange={handleSelectOwnerImage} />
                            </div>
                            <div className="flex flex-col w-full">
                                <p className="text-black3 text-[18px] md:text-[20px] font-[600] font-Urbanist mb-3">
                                    Presentation of the Project Owner
                                </p>
                                <div className="flex justify-between gap-2">
                                    <Input
                                        size="small"
                                        placeholder=""
                                        className="max-w-[300px]"
                                        value={carInfo?.ownerPresentation?.name}
                                        setValue={(value) => handleChangeOwnerPresentation("name", value)}
                                    />
                                    <IconButton
                                        text="Add" TxSize="text-[14px]" py="py-[6px]" className="w-[60px]"
                                        onClick={handleAddOwnerPresentationInfo}
                                    />
                                </div>
                            </div>
                        </div>
                        {carInfo?.ownerPresentation?.infos?.map((item, index) =>
                            <div key={index} className="w-full flex flex-row gap-3 mt-[20px]">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-center bg-background4 w-[28px] h-[28px] rounded-full">
                                        <img src={CheckImg} alt="check" className="w-[14px] h-[14px]" />
                                    </div>
                                    <div className="flex items-center justify-center bg-background4 w-[28px] h-[28px] rounded-full mt-4 cursor-pointer"
                                        onClick={() => handleRemoveOwnerPresentationInfo(index)}>
                                        <img src={TrashImg} alt="check" className="w-[14px] h-[14px]" />
                                    </div>
                                </div>
                                <div className="flex flex-col w-full gap-2">
                                    <Input
                                        size="small"
                                        placeholder="title"
                                        value={item.title}
                                        className="max-w-full"
                                        setValue={(value) => handleChangeOwnerPresentationInfo(index, "title", value)}
                                    />
                                    <Textarea
                                        placeholder="description"
                                        rows={2}
                                        value={item.content}
                                        setValue={(value) => handleChangeOwnerPresentationInfo(index, "content", value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="w-full  bg-white rounded-[12px] p-[20px]">
                        <div className="flex  flex-row items-center gap-4">
                            <div className="flex flex-col ">
                                <p className="text-black3 text-[18px] md:text-[20px] font-[600] font-Urbanist">
                                    Project Timeline
                                </p>
                            </div>
                            <div className="flex-grow flex items-end justify-end self-end">
                                <IconButton
                                    text="Add" TxSize="text-[14px]" py="py-[6px]" className="w-[60px]"
                                    onClick={handleAddTimeline}
                                />
                            </div>
                        </div>
                        {carInfo?.timeline?.map((item, index) =>
                            <div key={index} className="flex flex-row items-center gap-4 mt-[20px]">
                                <div className="flex flex-row w-full gap-4">
                                    <Input
                                        placeholder="description"
                                        value={item.desc}
                                        setValue={(value) => handleChangeTimeline(index, "desc", value)}
                                    />
                                    <DateSinglePicker
                                        date={item.date}
                                        setDate={(value) => handleChangeTimeline(index, "date", value)}
                                    />
                                </div>
                                <div className="flex items-center justify-center bg-background4 w-[28px] h-[28px] rounded-full cursor-pointer"
                                    onClick={() => handleRemoveTimeline(index)}>
                                    <img src={TrashImg} alt="check" className="w-[14px] h-[14px]" />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Upload Documents</p>
                        </div>
                        <div className="w-full border border-[#0000001A] p-3 md:p-6 rounded-lg">
                            <div className="text-black text-[16px] font-[500] mb-3">Upload Photos</div>
                            <DropZone
                                kind="cars"
                                type="image"
                                accept={{
                                    'image/jpeg': [],
                                    'image/png': []
                                }}
                                maxFiles={5}
                                files={carInfo?.photos}
                                onAcceptFiles={handleAcceptPhotos}
                                onDeleteFiles={() => handleDeleteFiles('photos')}
                            />
                        </div>
                        <div className="w-full border border-[#0000001A] p-3 md:p-6 rounded-lg">
                            <div className="text-black text-[16px] font-[500] mb-3">Upload Proof of purchase</div>
                            <DropZone
                                kind="cars"
                                maxFiles={1}
                                files={carInfo?.proofDoc ? [carInfo?.proofDoc] : []}
                                onAcceptFiles={(files) => handleAcceptFile('proofDoc', files)}
                                onDeleteFiles={() => handleDeleteFiles('proofDoc')}
                            />
                        </div>
                        <div className="w-full border border-[#0000001A] p-3 md:p-6 rounded-lg">
                            <div className="text-black text-[16px] font-[500] mb-3">Upload Key investment information sheet</div>
                            <DropZone
                                kind="cars"
                                maxFiles={1}
                                files={carInfo?.investSheet ? [carInfo?.investSheet] : []}
                                onAcceptFiles={(files) => handleAcceptFile('investSheet', files)}
                                onDeleteFiles={() => handleDeleteFiles('investSheet')}
                            />
                        </div>
                        <div className="w-full border border-[#0000001A] p-3 md:p-6 rounded-lg">
                            <div className="text-black text-[16px] font-[500] mb-3">Upload Terms and Conditions of NFT</div>
                            <DropZone
                                kind="cars"
                                maxFiles={1}
                                files={carInfo?.nftTerms ? [carInfo?.nftTerms] : []}
                                onAcceptFiles={(files) => handleAcceptFile('nftTerms', files)}
                                onDeleteFiles={() => handleDeleteFiles('nftTerms')}
                            />
                        </div>
                        <div className="w-full border border-[#0000001A] p-3 md:p-6 rounded-lg">
                            <div className="text-black text-[16px] font-[500] mb-3">Upload Car registration</div>
                            <DropZone
                                kind="cars"
                                maxFiles={1}
                                files={carInfo?.registrationDoc ? [carInfo?.registrationDoc] : []}
                                onAcceptFiles={(files) => handleAcceptFile('registrationDoc', files)}
                                onDeleteFiles={() => handleDeleteFiles('registrationDoc')}
                            />
                        </div>
                    </div>

                    <div className="mt-[30px] w-full flex justify-center lg:justify-start">
                        <IconButton
                            text="Save Car" TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[150px]"
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarEdit;