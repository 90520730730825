// @ts-nocheck

import React, { useEffect, useState } from "react";

import Pagination from "../../components/Pagination";
import { getNfts } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import { dollarFormatter, getBlockchainAccountLink, getBlockchainHashLink, getFileUrl, getTrimedAddress, showConfirmBox } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import CSVIcon from "../../assets/icons/csv.svg";
import { Checkbox, Dialog, DialogBody, Radio } from "@material-tailwind/react";
import IconButton from "../../components/Buttons/IconButton";
import { getCarsList, assignCar } from "../../services/axios";
import Input from "../../components/Form/Input";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loading";

const NFTs = () => {

    const location = useLocation();
    const userInfo = location?.state;

    const [dataList, setDataList] = useState([]);
    const [exportDataList, setExportDataList] = useState([]);
    const [checkedIds, setCheckedIds] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize] = useState(10);

    const [showUnrevealed, setShowUnrevealed] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [carName, setCarName] = useState("");
    const [carList, setCarList] = useState([]);
    const [selectedCar, setSelectedCar] = useState(null);

    useEffect(() => {
        loadPage();
    }, [showUnrevealed]);

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getNfts({
            page, pageSize,
            ownerAddress: userInfo ? userInfo.depositAddress : null,
            showUnrevealed: showUnrevealed ? 1 : 0
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
            setCheckedIds([]);
        }
        setCurPage(page);
        setIsLoading(false);
    }

    const handleExport = async (event, done) => {
        const res = await getNfts({
            page: 1, totalCount
        });
        if (res.status === 200) {
            setExportDataList(res.data.items.map(item => ({
                Name: item.name,
                CreatedAt: dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE),
                OwnerAddress: item.ownerAddress,
                NFTAddress: item.address,
                Amount: item.amount,
            })));
            done(true);
        } else {
            done(false);
        }
    }

    const handleCheckAll = (checked) => {
        setCheckedIds(checked ? dataList.map(item => item._id) : []);
    }

    const handleCheck = (item, checked) => {
        if (checked && !checkedIds.includes(item._id)) {
            setCheckedIds([...checkedIds, item._id]);
        } else if (!checked && checkedIds.includes(item._id)) {
            setCheckedIds(checkedIds.filter(id => id !== item._id));
        }
    }

    const loadCarList = async () => {
        const res = await getCarsList({
            page: 1, pageSize: 10,
            keyword: carName,
            status: [1, 2, 3]
        });
        if (res.status === 200) {
            setCarList(res.data.items);
        }
    }

    useEffect(() => {
        loadCarList();
    }, [carName]);

    const handleAssignCar = async () => {
        showConfirmBox("Information", "Are you sure to assign car?",
            async () => {
                setIsLoading(true);
                const res = await assignCar(checkedIds, selectedCar);
                if (res.status === 200) {
                    toast.success("Car assigned successfully");
                    setIsModalVisible(false);
                    loadPage();
                } else {
                    toast.error("Failed to assign car");
                }
                setIsLoading(false);
            }
        );
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex flex-col md:flex-row justify-between items-center px-[32px] py-[20px] border-b border-border2 gap-4">
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        NFT Transactions
                    </div>
                    <div className="text-gray6 text-[12px] font-[600] flex items-center gap-2">
                        {showUnrevealed && checkedIds.length > 0 && (
                            <IconButton
                                TxSize="text-[14px]" py="py-[8px]" className="w-[130px]"
                                text="Assign Car"
                                onClick={() => setIsModalVisible(true)}
                            />
                        )}

                        <IconButton
                            TxSize="text-[14px]" py="py-[8px]" className="w-[130px]"
                            text={showUnrevealed ? "Show All" : "Unrevealed"}
                            onClick={() => {
                                setShowUnrevealed(!showUnrevealed);
                                setSelectedCar(null);
                                setCarName("");
                            }}
                        />

                        <CSVLink
                            data={exportDataList}
                            filename={"NFTList.csv"}
                            asyncOnClick={true}
                            onClick={handleExport}
                            className="flex justify-center items-center gap-2 w-[130px] border border-gray6 text-gray6 text-[14px] hover:bg-[#eeeeee] rounded-md py-[8px]"
                        >
                            <img src={CSVIcon} alt="csv" />
                            Download CSV
                        </CSVLink>
                    </div>
                </div>

                <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                                <p className="w-[20%] min-w-[200px] text-gray6 text-[14px] font-[500]">
                                    {showUnrevealed ?
                                        <Checkbox
                                            color="green"
                                            label="NFT Name"
                                            checked={dataList.length === checkedIds.length}
                                            onChange={e => handleCheckAll(e.target.checked)}
                                        />
                                        :
                                        <span>NFT Name</span>
                                    }
                                </p>
                                <p className="w-[15%] min-w-[100px] text-gray6 text-[14px] font-[500]">Date</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Owner</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Type</p>
                                <p className="w-[15%] min-w-[100px] text-gray6 text-[14px] font-[500]">Amount</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Status</p>
                            </div>
                            {dataList.map((item, index) =>
                                <div key={index} className={`w-full min-w-fit flex items-center justify-between gap-1 border-b border-border2 ${showUnrevealed ? "py-1" : "py-3"}`}>
                                    <div className="w-[20%] min-w-[200px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {showUnrevealed ?
                                                <Checkbox
                                                    color="green"
                                                    label={item.name}
                                                    checked={checkedIds.includes(item._id)}
                                                    onChange={e => handleCheck(item, e.target.checked)}
                                                />
                                                :
                                                <span>{item.name}</span>
                                            }
                                        </p>
                                    </div>
                                    <div className="w-[15%] min-w-[100px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <a className="text-[14px] font-[500] text-black"
                                            href={getBlockchainAccountLink(item.ownerAddress)} target="_blank" rel="noreferrer"
                                        >
                                            {getTrimedAddress(item.ownerAddress)}
                                        </a>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            <span className={`${item.color === 'Black' ? 'text-black' :
                                                item.color === 'Purple' ? 'text-[#a05cff]' :
                                                    item.color === 'Green' ? 'text-[#47be18]' :
                                                        item.color === 'Gold' ? 'text-[#efbf04]' :
                                                            item.color === 'Silver' ? 'text-[#c4c4c4]' :
                                                                item.color === 'Bronze' ? 'text-[#ce8947]' :
                                                                    'text-gray'
                                                }`}>
                                                {item.color}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="w-[15%] min-w-[100px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {item.amount ? dollarFormatter(item.amount) + " USDC" : "-"}
                                        </p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        <a className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-center cursor-pointer"
                                            href={getBlockchainAccountLink(item.address)} target="_blank" rel="noreferrer"
                                        >
                                            {item.nid ?
                                                <span className="text-[#55BA68]">Revealed</span>
                                                :
                                                <span className="text-[#F7BA00]">Unrevealed</span>
                                            }
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <Pagination
                            totalCount={totalCount}
                            onGoToPage={(page) => loadPage(page)}
                            pageSize={pageSize}
                        />
                    </div>
                </div>
            </div>

            <Dialog open={isModalVisible} handler={() => { }}>
                <DialogBody className="relative p-8 flex flex-col items-center justify-between gap-2 max-h-[calc(100vh-20px)] h-[600px]">
                    <div className="absolute top-0 right-4 text-[30px] font-[600] cursor-pointer" onClick={() => setIsModalVisible(false)}>&times;</div>

                    <div className="w-full flex flex-col items-center gap-2">
                        <p className="text-black text-[20px] font-[600] text-center mb-3">Assign Car</p>
                        <Input placeholder="Search Car" value={carName} setValue={setCarName} className="max-w-full w-[300px] mb-3" />

                        {carList.length > 0 ? (
                            <div className="w-full flex flex-col items-center justify-center gap-2">
                                {carList.map((item, index) => (
                                    <div key={index}
                                        className="w-full flex items-center justify-start gap-2 hover:bg-[#f5f5f5] p-2 rounded-md cursor-pointer"
                                        onClick={() => setSelectedCar(item._id)}
                                    >
                                        <Radio
                                            color="green"
                                            checked={selectedCar === item._id}
                                            onClick={() => setSelectedCar(item._id)}
                                            onChange={() => { }}
                                        />
                                        <img src={getFileUrl(item.photos?.[0]?.path)} className="w-[150px] h-[80px] rounded-md" alt="car" />
                                        <div className="flex flex-col gap-1 ml-2">
                                            <p className="text-[16px] font-[500] text-black">{item.name}</p>
                                            <div className="text-[14px] text-gray6">
                                                <span className="font-[500]">Amount to fund: </span>
                                                <span>{dollarFormatter(item.amountFunded)} USDC</span>
                                            </div>
                                            <div className="text-[14px] text-gray6">
                                                <span className="font-[500]">Vehicle History: </span>
                                                <span>{item.resaleYear}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray6 text-[16px] py-10 font-[500] text-center">No car found</p>
                        )}
                    </div>
                    <IconButton
                        text="Assign Car"
                        TxSize="text-[14px]" py="py-[10px]" className="w-[200px]"
                        disabled={!selectedCar || isLoading}
                        onClick={handleAssignCar}
                    />
                </DialogBody>
            </Dialog>
        </>
    );
};

export default NFTs;